<template>
  <div class="aquapark-news">
    <div class="aquapark-news__container _container">
      <h1 class="aquapark-news__title main-title _blue">Новости</h1>
      <div class="aquapark-news__body">
        <div class="aquapark-news__items">
          <div
            class="aquapark-news__item"
            v-for="newsItem in news"
            :key="newsItem.id"
          >
            <router-link
              :to="{ name: 'news-item', params: { slug: newsItem.slug } }"
              class="aquapark-news__wrapper"
            >
              <div class="aquapark-news__image _ibg">
                <img
                  :src="'https://admin.san-remo.kz/' + newsItem.img"
                  alt=""
                />
              </div>
              <div class="aquapark-news__main">
                <div class="aquapark-news__content">
                  <div class="aquapark-news__date">
                    <span class="aquapark-news__icon"
                      ><img src="../assets/icons/calendar.svg" alt=""
                    /></span>
                    {{ formattDate(newsItem.title.created_at) }}
                  </div>
                  <div class="aquapark-news__name">
                    {{ newsItem.title[$lang] }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["news"]),
  },
  methods: {
    ...mapActions(["fetchNews"]),
    formattDate(date) {
      return new Date(date).toLocaleString().split(",")[0];
    },
  },
  created() {
    this.fetchNews();
  },
};
</script>

<style lang="scss">
.aquapark-news {
  padding: 50px 0 70px 0;
  background: #44C45F;
  @media (max-width: 991.98px) {
    padding: 41px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0;
  }
  &__title {
    margin: 0 0 100px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 85px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 71px 0;
    }
  }
  &__items {
    @media (min-width: $mobile + px) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 48px 20px;
    }
  }
  &__item {
    overflow: hidden;
    @media (min-width: $mobile + px) {
      align-self: normal;
      flex: 0 1 350px;
      background: #ffffff;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
    }
  }

  &__wrapper {
    @media (min-width: $mobile + px) {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__main {
    @media (min-width: $mobile + px) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    padding: 24px 16px 32px;
    @media (max-width: $mobileSmall + px) {
      padding: 14px 10px 20px;
    }
  }

  &__image {
    width: 100%;
    height: 260px;
    @media (max-width: $mobileSmall + px) {
      height: 160px;
    }
  }
  &__date {
    font-weight: 500;
    font-size: 15px;
    line-height: 133.3333%;
    color: #626262;
    margin: 0 0 10px 0;
    display: flex;
    align-items: center;
    @media (max-width: $mobileSmall + px) {
      font-size: 12px;
      margin: 0 0 5px 0;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0;
    @media (max-width: $mobileSmall + px) {
      width: 13px;
      height: 13px;
      img {
        width: 9.75px;
        height: 9.75px;
      }
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 18px;
    line-height: 138.888%;
    color: #2c3131;
    margin: 0 0 8px 0;
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }
  &__bottom {
    margin-top: auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: #626262;
    @media (max-width: $mobileSmall + px) {
      font-size: 12px;
    }
  }
}
</style>
