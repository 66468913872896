<template>
  <main-slider @scroll="scrollTo"></main-slider>
  <AquaparkTariffs></AquaparkTariffs>
  <MainEvents></MainEvents>
  <aquapark-block id="aquapark-info"></aquapark-block>
  <!-- <AquaparkMaps></AquaparkMaps> -->
  <MainFaq></MainFaq>
  <aquapark-reviews></aquapark-reviews>
</template>

<script>
import MainSlider from "@/views/home/MainSlider";
import AquaparkTariffs from "@/views/home/AquaparkTariffs";
import MainEvents from "@/views/home/MainEvents";
import AquaparkInfo from "@/views/home/AquaparkInfo";
import AquaparkMaps from "@/views/home/AquaparkMaps";
import MainFaq from "@/views/home/MainFaq";
import AquaparkReviews from "@/views/home/AquaparkReviews";
import AquaparkSpa from "@/views/home/AquaparkSpa";
import AquaparkBlock from './home/AquaparkBlock.vue';
import { useHead } from "@vueuse/head";
import { computed, reactive } from "vue"
export default {
  components: {
    AquaparkSpa,
    AquaparkReviews,
    MainFaq,
    AquaparkMaps,
    AquaparkInfo,
    MainEvents,
    AquaparkTariffs,
    MainSlider,
    AquaparkBlock,
  },
  props: ["links"],
  mounted() {
    setTimeout(() => {
      if (
        this.links === "tariffs" &&
        this.$router.options.history.state.back === "/basket"
      )
        this.scrollTo(this.links);
    }, 100);
    console.log(this.links);
  },
  methods: {
    scrollTo(link) {
      const offsetTop = document.getElementById(link).offsetTop;
      document.getElementById(link).scrollIntoView({
        top: offsetTop,
        behavior: "smooth",
      });
    },
  },
  created() {
    useHead({
      title: "Gala",
      meta: [
        { name: "description", content: "Gala" },
        // other meta tags if needed
      ],
    });
  },
  beforeMount() {},
  watch: {
    links() {
      console.log("asdasdasda sdsa da s", this.links);
      this.scrollTo(this.links);
    },
  },
};
</script>
