<template>
    <div class="blockBack">
    <div class="block _container">
        <div class="block__left">
            <p>Аквапарк San-Remo</p>
            <img src="@/assets/icons/banner.jpg"/>
            <button @click="$router.push('/info')">{{ $t("more") }}</button>
        </div>
        <div class="block__left">
            <p>SPA-центр San-Remo SPA</p>
            <img src="@/assets/icons/banner1.jpg"/>
            <button @click="$router.push('/spa')">{{ $t("more") }}</button>
        </div>
    </div>
</div>
</template>
<script>
export default {
    
}
</script>
    <style lang="scss" scoped>
    .blockBack{
        background: #44C45F;
        padding: 65px 0;
        @media (max-width: 991.98px) {
            padding: 45px 0;
        }
        @media (max-width: 479.98px) {
            padding: 32px 0;
        }
    }
    .block{
        display: flex;
        justify-content: space-between;
        &__left{
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: center;
            p{
               font-size: 30px; 
               font-weight: 700;
               color: #ffffff;
               text-transform: uppercase;
            }
            img{
                width: 100%;
                aspect-ratio: 1.65;
                object-fit: cover;
                border-radius: 10px;
            }
            button{
                width: 40%;
                border-radius: 60px;
                font-size: 20px;
                color: white;
                background: #39A24A;
                padding: 20px 0px;
            }
        }
        @media(max-width:768px){
            flex-direction: column;
            gap: 30px;
            &__left{
                width: 100%;
                gap: 20px;
                p{
                    font-size: 20px;
                }
                button{
                    padding: 10px 0px;
                    font-size: 14px;
                }
            }
        }
    }
</style>