<template>
  <div class="reviews">
    <div class="reviews__container _container">
      <h1 class="reviews__title main-title">{{ $t("revieus") }}</h1>
      <div class="reviews__content">
        <swiper
          :slides-per-view="3"
          :spaceBetween="-45"
          class="reviews__swiper"
          :centered-slides="true"
          :loop="true"
          :speed="550"
          :autoplay="{ delay: 2500 }"
          :breakpoints="breakpoints"
          :navigation="navigation"
        >
          <swiper-slide v-for="(review, i) in reviews" :key="i">
            <img
              class="reviews__background"
              src="@/assets/icons/slide-2.png"
              alt=""
            />
            <div class="reviews__user-info">
              <div class="reviews__icon">
                <img src="@/assets/icons/reviews/user.svg" alt="" />
              </div>
              <div class="reviews__name">{{ review.name }}</div>
              <div class="reviews__small-title">
                {{ review.date }}
              </div>
              <div class="reviews__commas">
                <img src="@/assets/icons/comma.svg" alt="" />
                <img src="@/assets/icons/comma.svg" alt="" />
              </div>
            </div>
            <div class="reviews__text" v-html="review.review"></div>
          </swiper-slide>
        </swiper>
        <div class="reviews-swiper-button-prev navigation-prev">
          <img src="@/assets/icons/arrow.svg" alt="" />
        </div>
        <div class="reviews-swiper-button-next navigation-next">
          <img src="@/assets/icons/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, {
  Pagination,
  Navigation,
  EffectFade,
  Autoplay,
} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { mapActions, mapGetters, mapState } from "vuex";

SwiperCore.use([Pagination, Navigation, EffectFade, Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      navigation: {
        nextEl: ".reviews-swiper-button-next",
        prevEl: ".reviews-swiper-button-prev",
      },
      breakpoints: {
        1366: {
          slidesPerView: 3,
          loop: true,
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 40,
          centeredSlides: false,
        },
        767: {
          centeredSlides: false,
          spaceBetween: 15,
          slidesPerView: 2,
          loop: true,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: true,
        },
      },
    };
  },
  computed: {
    ...mapState(["reviews"]),
    ...mapGetters(["getLang"]),
  },
  created() {
    this.fetchReviews();
  },
  methods: {
    ...mapActions(["fetchReviews"]),
    formatDate(date) {
      const [day, month, year] = date.split("-");
      return new Date(+year, +month, +day).toLocaleDateString(
        this.getLang === "ru" ? "ru-RU" : "kk-KK",
        {
          year: "numeric",
          day: "numeric",
          month: "long",
        }
      );
    },
  },
};
</script>

<style lang="scss">
.reviews {
  font-family: "Nunito";
  background: #44C45F;
  padding: 65px 0;
  @media (max-width: 767.98px) {
    padding: 32px 0;
  }

  &__title {
    color: #ffffff !important;
    margin: 0 0 149px 0;

    &:after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 767.98px) {
      margin: 0 0 63px 0;
    }
  }

  &__content {
    position: relative;
    padding: 0 10px;
    @media (max-width: 767.98px) {
      padding: 0;
    }
  }

  &__swiper {
    height: 530px;
    @media (max-width: $laptop + px) {
      width: 850px;
      height: 500px;
    }
    @media (max-width: $tablet + px) {
      width: 660px;
      height: 390px;
    }
    @media (max-width: $mobile + px) {
      height: 410px;
      width: 330px;
    }

    @media (max-width: $mobileSmall + px) {
      height: 345px;
      width: 300px;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-slide {
      position: relative;
      padding: 0 35px;
      @media (min-width: $laptop + px) {
        height: 450px;
        transform: scale(0.64, 0.686) !important;
        transition: all 0.3s ease 0s;
        &-active {
          transform: scale(1) !important;
        }
      }
      @media (max-width: $laptop + px) {
        height: 400px;
      }
      @media (max-width: $tablet + px) {
        height: 320px;
      }
      @media (max-width: $mobile + px) {
        padding: 0 30px;
      }
      @media (max-width: $mobileSmall + px) {
        height: 290px;
        padding: 0 10px;
      }
    }
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }

  &__user-info {
    text-align: center;
  }

  &__icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #39A24A;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: -40px auto 24px;
    @media (max-width: $tablet + px) {
      width: 85px;
      height: 85px;
      margin: -35px auto 16px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 50px;
      height: 50px;
      margin: -25px auto 8px;
    }

    img {
      width: 78px;
      height: 91px;
      @media (max-width: $tablet + px) {
        width: 54px;
        height: 63px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 30.83px;
        height: 35.83px;
      }
    }
  }

  &__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 139%;
    color: #525252;
    @media (max-width: $tablet + px) {
      font-size: 18px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 16px;
    }
  }

  &__small-title,
  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 135.7%;
  }

  &__small-title {
    color: #626262;
    @media (max-width: $tablet + px) {
      font-size: 14px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 12px;
    }
  }

  &__commas {
    width: 44px;
    height: 44px;
    margin: 0 auto 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $tablet + px) {
      width: 34px;
      height: 34px;
      margin: 0 auto 4px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 24px;
      height: 24px;
      margin: 0 auto 1px;
    }

    img {
      width: 11.46px;
      height: 19.42px;
      @media (max-width: $tablet + px) {
        width: 10.46px;
        height: 16.42px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 8px;
        height: 13px;
      }

      :not(:last-child) {
        margin: 0 2.13px 0 0;
        @media (max-width: $mobileSmall + px) {
          margin: 0 2px 0 0;
        }
      }
    }
  }

  &__text {
    color: #454545;
    margin: 0 auto;
    text-align: center;
    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      padding: 0 35px 45px 22px;
      max-height: 110px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .reviews-swiper-button-next,
  .reviews-swiper-button-prev {
    background: #39A24A;
    &.navigation-prev {
      @media (max-width: $mobile + px) {
        left: 50px;
      }
      @media (max-width: 614px) {
        left: 0;
      }
    }
    &.navigation-next {
      @media (max-width: $mobile + px) {
        right: 50px;
      }
      @media (max-width: 614px) {
        right: 0;
      }
    }
    @media (max-width: 400px) {
      display: none;
    }
  }
}
</style>
