<template>
  <div class="payment-rules">
    <div class="payment-rules__container _container">
      <div class="payment-rules__head">
        <h1 class="payment-rules__title main-title">
          {{ rule.title }}
        </h1>
        <div class="payment-rules__text" v-html="rule.description"></div>
      </div>
      <div class="payment-rules__body">
        <a
          :href="'https://admin.san-remo.kz/' + rule.file_ru"
          download
          class="payment-rules__file"
        >
          <span class="payment-rules__icon">
            <img src="@/assets/icons/download-file.svg" alt="" />
          </span>
          {{ $t("knowDocument") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["rule"]),
  },
  methods: {
    ...mapActions(["fetchRule"]),
  },
  created() {
    this.fetchRule(this.$route.params.name);
  },
  watch: {
    $route() {
      this.fetchRule(this.$route.params.name);
    },
  },
};
</script>

<style lang="scss">
.payment-rules {
  background: #E8F1F3;
  padding: 100px 0 130px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 64px 0;
  }
  @media (max-width: 991.98px) {
    padding: 65px 0 130px 0;
  }

  &__head {
    position: relative;
    margin: 0 0 127px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 100px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 70px 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 24.39px);
      text-align: center;
      width: 165.35px;
      height: 47.23px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("@/assets/icons/title-icon.svg");
      @media (max-width: $laptop + px) {
        width: 125.35px;
        height: 36.25px;
      }
      @media (max-width: 767.98px) {
        width: 82.36px;
        height: 23.52px;
      }
    }
  }

  &__title {
    margin: 0 0 24px 0;
    color: #256ed8;
    @media (max-width: $tablet + px) {
      margin: 0 0 16px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 8px 0;
    }
  }

  &__text {
    max-width: 680px;
    font-weight: 400;
    font-size: 18px;
    margin: 0 auto;
    line-height: 138.888%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2c3131;
    @media (max-width: $tablet + px) {
      font-size: 16px;
      max-width: 500px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__body {
    margin: 0 auto;
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.02em;
    color: #339CB5;
    @media (max-width: $tablet + px) {
      font-size: 18px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      max-width: 80%;
      margin: 0 auto;
    }
    @media (max-width: 300px) {
      max-width: 100%;
    }
  }

  &__icon {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 6px 0 0;
    @media (max-width: $tablet + px) {
      width: 30px;
      height: 30px;
    }
    @media (max-width: $mobileSmall + px) {
      width: 24px;
      height: 24px;
      margin: 0 5px 0 0;
    }

    img {
      width: 33px;
      height: 32px;
      @media (max-width: $tablet + px) {
        width: 26px;
        height: 26px;
      }
      @media (max-width: $mobileSmall + px) {
        width: 21px;
        height: 20px;
      }
    }
  }
}
</style>
