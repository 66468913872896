<template>
  <div className="news-item">
    <div class="news-item__container _container">
      <div class="news-item__breadcrumbs main-breadcrumbs">
        <router-link to="/" className="main-breadcrumbs__link">{{
          $t("mainPage")
        }}</router-link>
        <router-link to="" className="main-breadcrumbs__link">Новости</router-link>
      </div>
      <div class="news-item__image">
        <img :src="'https://admin.san-remo.kz/' + newsItem.img" alt="" />
      </div>
      <h1 class="news-item__title main-title _blue">
        {{ newsItem.title[$lang] }}
      </h1>
      <div
        class="news-item__content"
        v-html="newsItem.description[$lang]"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {},
  computed: {
    ...mapState(["news"]),
    ...mapGetters(["newsById"]),
    newsItem() {
      return this.newsById(this.$route.params.slug);
    },
  },
  methods: {
    ...mapActions(["fetchNews"]),
  },
  created() {
    this.fetchNews();
  },
};
</script>

<style lang="scss">
.news-item {
  padding: 48px 0 257px 0;
  background: #44C45F;
  @media (max-width: $tablet + px) {
    padding: 36px 0 174.5px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 24px 0 32px 0;
  }

  &__breadcrumbs {
    margin: 0 0 48px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 36px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    margin: 0 0 70px 0;
    padding-bottom: 38.92%;
    height: 0;
    overflow: hidden;
    @media (max-width: $tablet + px) {
      margin: 0 0 47px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    max-width: 680px;
    margin: 0 auto 24px;
    @media (max-width: $tablet + px) {
      margin: 0 auto 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 20px;
    }
  }

  &__content {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.8%;
    letter-spacing: 0.02em;
    color: #2c3131;
    margin: 130px 0 0 0;
    h1 {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.02em;
    }

    @media (max-width: $tablet + px) {
      font-size: 16px;
      margin: 99.52px 0 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 50.48px 0 0 0;
    }
  }
}
</style>
