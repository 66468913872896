<template>
  <div class="spa" id="spa" v-if="spa">
    <div class="spa__banner">
      <img src="@/assets/icons/banner1.jpg"/>
    </div>
    <div class="spa__container _container">
      <h1 class="spa__title main-title">{{ $t("nav.spa") }}</h1>
      <div class="spa__body">
        <div class="spa__desc">
            <p class="spa__desc__title">{{ $t("more") }}</p>
            <p v-html="spa?.spa[0]?.description[$lang]" claas="spa__desc__text"></p>
          </div>
          <p class="spa__desc__title">{{ $t("main.price") }}</p>
          <div class="spa-price">
            <div class="spa-price__image _ibg">
              <img
                loading="lazy"
                :src="'https://admin.san-remo.kz/' + spa?.spaprice[0]?.file"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="spa-menu">
            <div class="spa-menu__image _ibg">
              <img
                loading="lazy"
                :src="'https://admin.hawaii.kz/' + spa.spamenu[0].image"
                alt=""
              />
            </div>
          </div> -->
          <p class="spa__desc__title">{{ $t("main.menu") }}</p>
          <div class="aquapark__menu aquapark-menu">
            <div class="aquapark-menu__content">
              <div class="aquapark-menu__text" v-html="spa?.spamenu[0]?.description">
              </div>

              <ul
                class="aquapark-menu__list"
              >
                <li>
                  <a
                    :href="
                      'https://admin.san-remo.kz/' + spa?.spamenu[0]?.file
                    "
                    download
                  >
                    <span class="aquapark-menu__icon"
                      ><img src="@/assets/icons/file.svg" alt=""
                    /></span>
                    <p style="color: #ffffff">{{ spa?.spamenu[0]?.open_menu }}</p>
                  </a>
                </li>
              </ul>
            </div>
            <swiper
              :slidesPerView="1"
              :pagination="{ clickable: true }"
              class="aquapark-menu__swiper"
            >
              <swiper-slide
                v-for="aquaparkSlide in spa?.spamenu"
                class="_ibg"
                :key="aquaparkSlide.id"
              >
                <img
                  :src="'https://admin.san-remo.kz/' + aquaparkSlide?.image"
                  alt=""
                />
              </swiper-slide>
            </swiper>
          </div>
          <p class="spa__desc__title">{{ $t("main.video") }}</p>
          <div class="spa__gallery spa-gallery">
            <swiper
              class="spa-gallery__swiper"
              :effect="'coverflow'"
              :slidesPerView="'auto'"
              :loop="true"
              :breakpoints="breakpoints"
              :speed="600"
              :navigation="photoNavigation"
              :modules="modules"
            >
              <swiper-slide
                v-for="photo in spa?.spabiggal"
                class="_ibg"
                :key="photo.id"
              >
                <img
                  :src="'https://admin.san-remo.kz/' + photo?.img"
                  alt=""
                />
              </swiper-slide>
            </swiper>
            <div class="photo-gallery-swiper-button-prev navigation-prev">
              <img src="@/assets/icons/arrow.svg" alt="" />
            </div>
            <div class="photo-gallery-swiper-button-next navigation-next">
              <img src="@/assets/icons/arrow.svg" alt="" />
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabItem from "@/components/TabItem";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, EffectFade } from "swiper";
import TabBlock from "@/components/TabBlock";
import { mapState, mapActions } from "vuex";
SwiperCore.use([Pagination, Navigation, EffectFade]);

export default {
  components: {
    TabBlock,
    TabItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      selectedTab: this.$t("main.aboutSPA"),
      photoNavigation: {
        nextEl: ".photo-gallery-swiper-button-next",
        prevEl: ".photo-gallery-swiper-button-prev",
      },
      videoNavigation: {
        nextEl: ".aquapark-videos-swiper-button-next",
        prevEl: ".aquapark-videos-swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          effect: "slide",
          allowTouchMove: true,
        },
        767: {
          effect: "slide",
          slidesPerView: 2,
          spaceBetween: 15,
        },
        991: {
          effect: "slide",
          slidesPerView: 3,
          spaceBetween: 20,
          centeredSlides: "false",
        },
        1366: {
          allowTouchMove: false,
          centeredSlides: "true",
          coverflowEffect: {
            stretch: 100,
            depth: 90,
            scale: 0.4,
            rotate: 0,
            modifier: 1,
          },
        },
      },
    };
  },
  computed: {
    ...mapState(["spa"]),
  },
  methods: {
    ...mapActions(["fetchSpa"]),
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
  created() {
    this.fetchSpa();
  },
};
</script>

<style lang="scss">
.spa {
  background: #44C45F;
  padding: 65px 0;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media(max-width:768px){
    gap: 25px;
  }
  @media (max-width: 991.98px) {
    padding: 90px 0;
  }
  @media (max-width: 479.98px) {
    padding: 32px 0;
  }
  &__body{
    display: flex;
    flex-direction: column;
    gap: 60px;
    @media(max-width:768px){
      gap: 40px;
    }
  }
  &__banner{
    margin: 0 auto;
    width: 1660px;
    @media(max-width:768px){
      width: 95%;
    }
    img{
      width: 100%;
      aspect-ratio: 2.6;
      object-fit: cover;
    }
  }
  &__desc{
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: center;
    @media(max-width:768px){
        gap: 20px;
    }
    &__title{
      font-size: 35px;
      color: #ffffff;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      @media(max-width:768px){
            font-size: 20px !important;
        }
    }
  }
  &__title {
    color: #00bed6;
    margin: 0 0 130px 0;

    &:after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 991.98px) {
      margin: 0 0 107px 0;
    }
    @media (max-width: 479.98px) {
      margin: 0 0 63px 0;
    }
  }

  &__content {
    @media (min-width: 991.98px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 40px;
    }
  }

  &__info {
    flex: 0 1 584px;
    font-weight: 600;
    font-size: 18px;
    line-height: 138.9%;
    letter-spacing: 0.02em;
    color: #2c3131;
    @media (max-width: 991.98px) {
      overflow: hidden;
      max-width: 585px;
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      font-size: 14px;
    }

    p {
      text-overflow: ellipsis;

      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
    }
  }

  &__more {
    font-weight: 600;
    font-size: 16px;
    line-height: 137.5%;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #256ed8;
  }

  &__items {
    margin: 16px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    flex: 1 1 49%;
    text-align: left;
  }

  &__icon {
    margin: 0 12px 0 0;
  }

  &__swiper {
    width: 536px;
    height: 600px;
    margin: 0;
    @media (max-width: 1366.98px) {
      width: 436px;
      height: 500px;
    }
    @media (max-width: 991.98px) {
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      width: 306px;
      height: 333px;
      margin: 0 auto 23px;
    }

    @media (max-width: 479.98px) {
      width: 206px;
      height: 233px;
    }

    .swiper-slide {
      border: 3px solid #00bed6;
      border-radius: 60px;
      height: 501px;
      overflow: hidden;
      @media (max-width: 1366.98px) {
        height: 401px;
      }
      @media (max-width: 767.98px) {
        border-radius: 15px;
        border-width: 2px;
        height: 300px;
      }
      @media (max-width: 479.98px) {
        height: 206px;
      }
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 45.83px;
      @media (max-width: 767.98px) {
        bottom: 0;
      }
    }

    .swiper-pagination-bullet {
      width: 8.33px;
      height: 8.33px;
      margin: 0 12.5px !important;
      background: #b3b3bb;
      opacity: 1;

      &-active {
        background: #00bed6;
      }

      @media (max-width: 767.98px) {
        margin: 0 6.25px !important;
        width: 4.17px;
        height: 4.17px;
      }
    }
  }
}

.spa-price,
.spa-menu {
  &__image {
    width: 952px;
    height: 675px;
    margin: 0 auto;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.25));
    @media (max-width: 991.98px) {
      width: 647px;
      height: 460px;
    }
    @media (max-width: 767.98px) {
      width: 447px;
      height: 310px;
    }
    @media (max-width: 479.98px) {
      width: 100%;
      height: 0;
      padding-top: 73.25%;
    }
  }
}

.spa-gallery {
  position: relative;
  margin: 0 0 130px 0;
  @media (max-width: 1366.98px) {
    padding: 0 100px;
  }
  @media (max-width: 767.98px) {
    margin: 0 0 65px;
  }
  @media (max-width: 479.98px) {
    padding: 0 50px;
  }

  &__swiper {
    height: 362px;
    @media (min-width: 1366.98px) {
      width: 1082px;
    }
    @media (max-width: 1366.98px) {
      height: 240px;
    }
    @media (max-width: 479.98px) {
      height: 175px;
    }
    @media (max-width: 389.98px) {
      height: 155px;
    }
    @media (max-width: 320.98px) {
      height: 135px;
    }

    .swiper-slide {
      overflow: hidden;
      height: 100%;
      transition: all 0.3s ease;
      border-radius: 30px;
      @media (min-width: 1366px) {
        width: 566px;
        top: 120px;
        opacity: 0.5;
      }
      @media (max-width: 479.98px) {
        border-radius: 15px;
        border: 2px solid #256ed8;
      }

      &-active {
        opacity: 1;
        border: none;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.25) !important;
        @media (min-width: 1366.98px) {
          transform: translate3d(0px, -120px, 0px) rotateX(0deg) rotateY(0deg)
            scale(1) !important;
        }

        @media (max-width: 479.98px) {
          border-radius: 15px;
          border: 2px solid #256ed8;
        }
      }
    }
  }

  .navigation-prev {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) !important;
    }
  }

  .navigation-next {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) rotate(180deg) !important;
    }
  }

  .photo-gallery-swiper-button-next,
  .photo-gallery-swiper-button-prev {
    background: #256ed8;
  }
}

.spa-videos {
  position: relative;
  @media (max-width: 1366.98px) {
    padding: 0 80px;
  }
  @media (max-width: 479.98px) {
    padding: 0 50px;
  }

  &__swiper {
    height: 362px;
    @media (min-width: 1366.98px) {
      width: 1082px;
    }
    @media (max-width: 1366.98px) {
      height: 240px;
    }
    @media (max-width: 479.98px) {
      height: 175px;
    }
    @media (max-width: 389.98px) {
      height: 155px;
    }
    @media (max-width: 320.98px) {
      height: 135px;
    }
    .swiper-slide {
      overflow: hidden;
      height: 100%;
      transition: all 0.3s ease;
      border-radius: 30px;
      @media (min-width: 1366px) {
        width: 566px;
        top: 120px;
        opacity: 0.5;
      }
      @media (max-width: 479.98px) {
        border-radius: 15px;
        border: 2px solid #256ed8;
      }

      &-active {
        opacity: 1;
        border: none;
        box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.25) !important;
        @media (min-width: 1366.98px) {
          transform: translate3d(0px, -120px, 0px) rotateX(0deg) rotateY(0deg)
            scale(1) !important;
        }

        @media (max-width: 479.98px) {
          border-radius: 15px;
          border: 2px solid #256ed8;
        }
      }
    }
  }

  .aquapark-videos-swiper-button-next,
  .aquapark-videos-swiper-button-prev {
    background: #256ed8;
  }

  .navigation-prev {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) !important;
    }
  }

  .navigation-next {
    @media (min-width: 1366.98px) {
      transform: translateY(90px) rotate(180deg) !important;
    }
  }
}

.aquapark-menu {
  display: flex;
  justify-content: space-between;
  gap: 0 20px;
  @media (max-width: 991.98px) {
    flex-direction: column-reverse;
    gap: 0;
  }

  &__content {
    @media (min-width: $tablet + px) {
      flex: 0 1 624px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.9%;
    letter-spacing: 0.02em;
    color: #2c3131;
    margin: 0 0 24px 0;

    text-align: justify;
    p {
      &:not(:last-child) {
        margin: 0 0 16px 0;
      }
      text-align: justify;
    }

    @media (max-width: 767.98px) {
      width: 343px;
      height: 133px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 14px;
      margin: 0 0 8px 0;
    }
  }

  &__list {
    @media (max-width: $mobile + px) {
      margin: 25px 0 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 13px 0 0 0;
    }
    li {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 0.02em;
      color: #256ed8;

      &:not(:last-child) {
        margin: 0 0 16px 0;
      }

      @media (max-width: 767.98px) {
        font-size: 16px;
        justify-content: center;
        &:not(:last-child) {
          margin: 0 0 12px 0;
        }
      }

      a {
        display: flex;
        align-items: center;
      }
    }
  }

  &__more {
    display: none;
    @media (max-width: 767.98px) {
      margin: 12px auto 16px;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 137.5%;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #256ed8;
    }
    @media (max-width: 479.98px) {
      margin: 8px auto 12px;
    }
  }

  &__icon {
    width: 35px;
    height: 35px;
    margin: 0 5px 0 0;
    display: flex;
    align-items: center;
    img{
      filter: brightness(0) saturate(100%) invert(100%) sepia(62%) saturate(28%) hue-rotate(105deg) brightness(106%) contrast(109%);
    }
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 24px;
      height: 24px;
      img {
        width: 16px;
        height: 21px;
      }
    }
  }
}
.aquapark-menu__swiper {
    width: 536px;
    height: 600px;
    margin: 0 !important;
    @media (max-width: 1366.98px) {
      width: 436px;
      height: 500px;
    }
    @media (max-width: 991.98px) {
      margin: 0 auto;
    }
    @media (max-width: 767.98px) {
      width: 306px;
      height: 333px;
      margin: 0 auto 23px;
    }

    @media (max-width: 479.98px) {
      width: 206px;
      height: 233px;
    }

    .swiper-slide {
      border: 3px solid #256ed8;
      border-radius: 60px;
      height: 501px;
      overflow: hidden;
      @media (max-width: 1366.98px) {
        height: 401px;
      }
      @media (max-width: 767.98px) {
        height: 306px;
        border-radius: 15px;
        border-width: 2px;
      }
      @media (max-width: 479.98px) {
        height: 206px;
      }
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 45.83px;
      @media (max-width: 767.98px) {
        bottom: 0;
      }
    }

    .swiper-pagination-bullet {
      width: 8.33px;
      height: 8.33px;
      margin: 0 12.5px !important;
      background: #b3b3bb;
      opacity: 1;

      &-active {
        background: #256ed8;
      }

      @media (max-width: 767.98px) {
        margin: 0 6.25px !important;
        width: 4.17px;
        height: 4.17px;
      }
    }
  }
</style>
