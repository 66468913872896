<template>
  <div class="aquapark-event">
    <div class="aquapark-event__container _container">
      <div class="aquapark-event__breadcrumbs main-breadcrumbs">
        <router-link
          @click="$router.go(-1)"
          to=""
          class="main-breadcrumbs__link"
          >{{ $t("mainPage") }}</router-link
        >
        <router-link to="" class="main-breadcrumbs__link">{{
          $t("aboutPromotion")
        }}</router-link>
      </div>
      <div class="aquapark-event__image">
        <img class="descktop" :src="'https://admin.san-remo.kz/' + event.big_img" alt="" />
        <img class="mobile" :src="'https://admin.san-remo.kz/' + event.img" alt="" />
      </div>
      <div class="aquapark-event__info">
        <h1 class="aquapark-event__title">{{ event.title[$lang] }}</h1>
        <div
          class="aquapark-event__short-text"
          v-html="event.short_description[$lang]"
        ></div>
      </div>

      <div
        class="aquapark-event__content"
        v-html="event.description[$lang]"
      ></div>
      <div class="aquapark-event__download">
        <a
          v-if="event.file"
          :href="'https://admin.san-remo.kz/' + event.file"
          >{{$t('documen')}}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  data() {},
  computed: {
    ...mapGetters(["eventById"]),
    event() {
      return this.eventById(this.$route.params.id);
    },
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    ...mapActions(["fetchEvents"]),
  },
};
</script>

<style lang="scss">
.aquapark-event {
  background: #44C45F;
  padding: 48px 0 130px 0;
  @media (max-width: $tablet + px) {
    padding: 36px 0 107px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 24px 0 71px 0;
  }

  &__breadcrumbs {
    margin: 0 0 48px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 36px 0;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }
  }

  &__info {
    @media (min-width: $mobile + px) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        background-image: url("@/assets/icons/title-icon.svg");
        transform: translate(-50%, 24.39px);
        text-align: center;
        width: 165.35px;
        height: 47.23px;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: $tablet + px) {
          width: 125.35px;
          height: 36.25px;
          transform: translate(-50%, 18.39px);
        }
        @media (max-width: $mobileSmall + px) {
          width: 82.36px;
          height: 23.52px;
          transform: translate(-50%, 10px);
        }
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    margin: 0 0 70px 0;
    padding-bottom: 38.92%;
    height: 0;
    overflow: hidden;
    @media (max-width: $tablet + px) {
      margin: 0 0 47px 0;
      height: auto;
      padding-bottom: 0px;
    }
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 24px 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      height: 100%;
      @media (max-width: $tablet + px) {
        position: relative;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 136.11%;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 0 24px 0;
    @media (max-width: $tablet + px) {
      margin: 0 0 16px 0;
      font-size: 28px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 20px;
      margin: 0 0 8px 0;
    }
  }

  &__short-text,
  &__content {
    font-weight: 400;
    font-size: 18px;
    line-height: 138.8%;
    letter-spacing: 0.02em;
    color: #2c3131;

    h1 {
      font-weight: 700;
      font-size: 18px;
      letter-spacing: 0.02em;
    }

    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__short-text {
    max-width: 680px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: $mobile + px) {
      display: none;
    }
  }

  &__content {
    margin: 130px 0 0 0;
    @media (max-width: $mobile + px) {
      position: relative;
      margin: 15px 0 0 0;
      text-align: center;
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        background-image: url("@/assets/icons/title-icon-blue.svg");
        transform: translate(-50%, 24.39px);
        text-align: center;
        width: 165.35px;
        height: 47.23px;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: $tablet + px) {
          width: 125.35px;
          height: 36.25px;
          transform: translate(-50%, 18.39px);
        }
        @media (max-width: $mobileSmall + px) {
          width: 82.36px;
          height: 23.52px;
          transform: translate(-50%, 10px);
        }
      }
    }
  }
  &__download {
    text-align: center;
    padding-top: 50px;
    a {
      color: #256ed8;
      text-decoration: underline !important;
    }
  }
}
</style>
