<template>
  <div class="events" id="stock">
    <div class="events__container _container">
      <h1 class="events__title main-title">{{ $t("main.promoEvents") }}</h1>
      <div class="events__body">
        <swiper
          :slides-per-view="4"
          :loop="true"
          :navigation="navigation"
          class="events__swiper"
          :space-between="24"
          :speed="550"
          :breakpoints="breakpoints"
        >
        <swiper-slide v-for="event in events" class="_ibg" :key="event.id">
        <router-link :to="{ name: 'event', params: { id: event.slug } }">
          <img :src="'https://admin.san-remo.kz/' + event.img" alt="" style="user-select: all; pointer-events: all"/>
        </router-link>
        <router-link
          :to="{ name: 'event', params: { id: event.slug } }"
          class="events__button base-button"
          @click="showEvent(event.slug)"
        >{{ $t("more") }}</router-link>
      </swiper-slide>
        </swiper>
        <div class="events-swiper-button-prev navigation-prev">
          <img src="@/assets/icons/arrow.svg" alt="" />
        </div>
        <div class="events-swiper-button-next navigation-next">
          <img src="@/assets/icons/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { mapState, mapActions, mapMutations } from "vuex";

SwiperCore.use([Pagination, Navigation]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      navigation: {
        nextEl: ".events-swiper-button-next",
        prevEl: ".events-swiper-button-prev",
      },
      breakpoints: {
        1309: {
          slidesPerView: 4,
        },
        767: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
      },
    };
  },
  computed: {
    ...mapState(["events"]),
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    ...mapActions(["fetchEvents"]),
    ...mapMutations(["SET_EVENT"]),
    selectAquaparkTab(tab) {
      this.selectedAquaparkTab = tab;
    },
    selectMapTab(tab) {
      this.selectedMapTab = tab;
    },
    showEvent(slug) {
      this.SET_EVENT(slug);
    },
    handleResize() {
      if (window.innerWidth < 1366.98) {
        this.visibleButton = false;
      } else {
        this.visibleButton = true;
      }
    },
  },
};
</script>

<style lang="scss">
.events {
  background: #44C45F;
  padding: 65px 0;
  @media (max-width: 767.98px) {
    padding: 32px 0;
  }

  &__title {
    color: #ffffff !important;
    margin: 0 0 151px 0;

    &::after {
      background-image: url("@/assets/icons/title-icon.svg");
    }

    @media (max-width: 767.98px) {
      margin: 0 0 63px 0;
    }
  }

  &__button {
    opacity: 0;
    z-index: 3;
    transition: opacity 0.4s ease 0.05s;
  }

  &__body {
    position: relative;
    padding: 0 94px;
    @media (max-width: 1366.98px) {
      padding: 0 60px;
    }
    @media (max-width: 991.98px) {
      padding: 0;
    }
  }

  &__swiper {
    height: 250px;
    @media (max-width: $mobile + px) {
      height: 200px;
      margin: 0 -16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      height: 160px;
    }
    .swiper-slide {
      height: 100%;
      border: 3px solid #ff7f3f;
      border-radius: 30px;
      overflow: hidden;
      transition: all 0.3s ease 0s;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: $mobile + px) {
        min-width: 200px;
      }
      @media (max-width: $mobileSmall + px) {
        min-width: 160px;
      }
      img {
        transition: all 0.3s ease 0s;
      }
      &:hover {
        img {
          opacity: 0.45;
        }
        background: #000000;
        .events__button {
          opacity: 1;
        }
      }
    }
  }

  .events-swiper-button-next,
  .events-swiper-button-prev {
    background: #39A24A;
    @media (max-width: $tablet + px) {
      display: none !important;
    }
  }
}
</style>
