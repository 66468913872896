<template>
  <div class="tab-items" :style="{'--color': color}">
    <ul class="tab-list">
      <li v-for="tab in tabItems" :key="tab" class="tab-item">
        <div :class="{active: tab === selected}" class="tab-link second-title" @click="setTab(tab)">{{ tab }}</div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    tabItems: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  },
  methods: {
    setTab(tab){
      this.$emit('setTab',tab)
    }
  }
}
</script>

<style lang="scss">

.tab-items{
  position: relative;
  &::after{
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid;
    border-bottom-color: #ffffff;
    z-index: 1;
  }
}
.tab-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.tab-item{
  &:first-child{
    .tab-link{
       &:before{
         display: none;
       }
    }
  }
}
.tab-link{
  cursor: pointer;
  padding: 17px 28px 8px;
  white-space: nowrap;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid #44C45F;
  transition: all 0.3s ease 0s;
  z-index: 1;
  color: var(--color);
  @media (max-width: 1366.98px) {
    padding: 13px 20px;
    font-size: 20px!important;
  }
  @media (max-width: 767.98px) {
    padding: 11px 12px 10px;
    font-size: 13px!important;
  }
  &.active{
    z-index: 2;
    border-color: #ffffff;
    border-bottom-color: #44C45F;
    background: #44C45F;
    color: #ffffff;
    &::before, &::after{
      border-color: #44C45F;
    }
    &:before{
    }
    &:after{
    }
  }
  @media (min-width: 991.98px) {
    &:before, &:after{
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      bottom: -1px;
      border: 1px solid #44C45F;
      transition: all 0.28s ease 0s;
    }
    &:before{
      left: -24px;
      border-bottom-right-radius: 30px;
      border-width: 0 1px 1px 0;
    }
    &:after{
      right: -24px;
      border-bottom-left-radius: 30px;
      border-width: 0 0 1px 1px;
    }
  }
}

</style>